<template>
    <div class="alert-page demo">
        <v-row class="sub-cont pt-0 pt-md-3 align-center">
            <v-col cols="12" md="10" offset-md="1" v-if="preview" class="pb-0">
                <v-alert type="info" dense class="mb-0 body-2 font-weight-medium">This is a preview of your alert page. Dates are for placement only.</v-alert>
            </v-col>
            <v-col cols="12" md="10" offset-md="1">
                <v-card class="Dno-br-bot elevation-2 bg-wavelines-secondary-dark" color="secondary" dark>
                    <v-card-text class="px-2 px-md-8 pb-2 pb-md-6 text-center d-flex align-center justify-center">
                        <div style="width: 100%;">
                            <v-row no-gutters class="no-print">
                                <v-col cols="12" md="3" class="text-center">
                                    <a target="_blank" href="demo-woman.jpeg" title="View Photo">
                                        <v-avatar class="action-item elevation-4 mt-0 mt-1 mt-md-2" size="135">
                                            <v-img class="img-border-primary elevation-3" src="demo-woman.jpeg" />
                                        </v-avatar>
                                    </a>
                                </v-col>
                                <v-col cols="12" md="9">
                                    <div style="max-width: 630px;" class="hidden-sm-and-down mx-auto">
                                        <h1 class="text-h4 white--text text-center mt-3 mt-md-3 mb-3 shadowed mx-auto lh-23">Jane Smith has not responded to their safety check-in and may need your help!</h1>
                                        <p class="mb-0 subtitle-1 text-center shadowed font-weight-medium white--text">
                                            <v-btn class="mx-2 mb-3 mb-sm-0" color="white" outlined dark text><v-icon small color="primary" class="mr-2">fa fa-phone</v-icon> Call {{ firstName }} (555) 555-5555</v-btn>
                                            <v-btn class="mx-2" target="_blank" color="white" outlined dark text><v-icon small color="primary" class="mr-2">fa fa-envelope</v-icon> Email {{ firstName }}</v-btn>
                                        </p>
                                    </div>
                                    <div class="hidden-md-and-up">
                                        <h1 class="title white--text text-center mt-3 mt-md-3 mb-4 shadowed mx-3 lh-15">Jane Smith has not responded to their safety check-in and may need your help!</h1>
                                        <p class="mb-0 subtitle-1 text-center shadowed font-weight-medium white--text">
                                            <v-btn class="mx-2 mb-2"  color="white" outlined dark text><v-icon small color="primary" class="mr-2">fa fa-phone</v-icon> Call (555) 555-5555</v-btn>
                                            <v-btn class="mx-2 mb-2" target="_blank" color="white" outlined dark text><v-icon small color="primary" class="mr-2">fa fa-envelope</v-icon> Email {{ firstName }}</v-btn>
                                        </p>
                                    </div>
                                </v-col>
                            </v-row>
                        </div>
                    </v-card-text>
                </v-card>
                <v-alert class="mt-2 mt-md-3 mb-0 py-2 white--text px-0 elevation-1 text-center no-print" color="error">
                    <v-icon color="white" large class="float-left ml-5 mt-1">fa fa-exclamation-triangle</v-icon>
                    <v-icon color="white" large class="hidden-sm-and-down float-right mt-1 mr-5">fa fa-exclamation-triangle</v-icon>
                    <div class="mx-auto" style="max-width: 300px;">
                        <h3 class="title lh-14 dshadowed-light white--text">
                            Alert Status: Active
                            <span class="body-2 d-block">
                                Issued {{ $moment().format('MMMM Do [at] h:mma') }} EST
                            </span>
                        </h3>
                    </div>
                </v-alert>
                <v-row dense class="mt-1 mt-md-2 no-print">
                    <v-col cols="12" md="12" lg="6">
                        <v-card class="fill-height" flat color="transparent">
                            <!-- <v-card-title class="hidden title pt-3 pb-2"><v-icon color="primary" class="mr-2">fa fa-info-circle</v-icon> What does this mean?</v-card-title> -->
                            <v-card-text class="secondary--text px-0 pb-0 pt-0">
                                <v-card class="elevation-2 fill-height" color="white">
                                    <v-card-text class="secondary--text pt-3">
                                        <p class="subtitle-1 mb-2 font-weight-medium text-center text-md-left">What does this mean?</p>
                                        <div class="d-flex align-center">
                                            <img src="/icons/doodle-clock-24.svg" :width="($vuetify.breakpoint.smAndDown) ? 45 : 35" class="d-inline-block align-self-start align-self-md-center mt-1 mt-lg-0 mr-5" />
                                            <p class="body-2 mb-0 lh-11">
                                                {{ firstName }} uses I&rsquo;mOkay.me to periodically receive check-in text messages to see if they're okay.
                                            </p>
                                        </div>
                                        <v-divider class="my-2" style="opacity:0.5;" />
                                        <div class="d-flex align-center">
                                            <img src="/icons/doodle-msg-waiting.svg" :width="($vuetify.breakpoint.smAndDown) ? 45 : 35" class="d-inline-block align-self-start align-self-md-center mt-1 mt-lg-0 mr-5" />
                                            <p class="body-2 mb-0 lh-11">
                                                {{ firstName }} has yet to respond to our last check-in request we sent on {{ $moment().format('MMMM Do [at] h:mma') }} (EST).
                                            </p>
                                        </div>
                                        <v-divider class="my-2" style="opacity:0.5;" />
                                        <div class="d-flex align-center">
                                            <img src="/icons/doodle-msg-exclam.svg" :width="($vuetify.breakpoint.smAndDown) ? 45 : 35" class="d-inline-block align-self-start align-self-md-center mt-1 mt-lg-0 mr-5" />
                                            <p class="body-2 mb-0 lh-11">
                                                We alerted the emergency contacts {{ firstName }} has provided to see if they can reach them.
                                            </p>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="12" lg="6">
                        <v-card class="fill-height" flat color="transparent">
                            <!-- <v-card-title class="hidden title pt-3 pb-2"><v-icon color="primary" class="mr-2">fa fa-info-circle</v-icon> What does this mean?</v-card-title> -->
                            <v-card-text class="secondary--text px-0 pb-0 pt-0">
                                <v-card class="elevation-2 fill-height" color="white">
                                    <v-card-text class="secondary--text pt-3">
                                        <p class="subtitle-1 mb-2 font-weight-medium text-center text-md-left">What should I do?</p>
                                        <div class="d-flex align-center">
                                            <img src="/icons/doodle-question.svg" :width="($vuetify.breakpoint.smAndDown) ? 45 : 35" class="d-inline-block align-self-start align-self-md-center mt-1 mt-lg-0 mr-5" />
                                            <p class="body-2 mb-0 lh-11">
                                                Don't panic. {{ firstName }} may simply be away from the phone or forgot to reply to our check-in message.
                                            </p>
                                        </div>
                                        <v-divider class="my-2" style="opacity:0.5;" />
                                        <div class="d-flex align-center">
                                            <img src="/icons/doodle-phone.svg" :width="($vuetify.breakpoint.smAndDown) ? 45 : 35" class="d-inline-block align-self-start align-self-md-center mt-1 mt-lg-0 mr-5" />
                                            <p class="body-2 mb-0 lh-11">
                                                Try contacting {{ firstName }} at <a class="font-weight-medium" >(555) 555-5555</a> or <a class="font-weight-medium"  target="_blank">jane.smith@hmail.com</a> to see if they're okay.
                                            </p>
                                        </div>
                                        <v-divider class="my-2" style="opacity:0.5;" />
                                        <div class="d-flex align-center">
                                            <img src="/icons/doodle-msg-info.svg" :width="($vuetify.breakpoint.smAndDown) ? 45 : 35" class="d-inline-block align-self-start align-self-md-center mt-1 mt-lg-0 mr-5" />
                                            <p class="body-2 mb-0 lh-11" v-if="alertUser.lng || alertUser.has_notes || alertUser.has_addresses">
                                                If you're unable to reach {{ firstName }}, review the information below they've asked us to share in case of an emergency.
                                            </p>
                                            <p class="body-2 mb-0 lh-11" v-else>
                                                When we hear from {{ firstName }} we will notify their emergency contacts and update this page.
                                            </p>
                                        </div>
                                    </v-card-text>
                                </v-card>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                
                <v-row dense class="no-print mt-2">
                    <v-col cols="12">
                        <v-card class="elevation-2 dbg-wavelines-secondary" color="secondary" dark>
                            <v-card-text class="white--text pt-2 px-1 pb-2 px-md-3 pb-md-3">
                                
                                <h3 class="title text-center"><v-icon color="primary" class="mr-2">fa fa-life-ring</v-icon> In Case of Emergency:</h3>
                                <v-divider class="mt-2 mb-3" />
                                <p class="subtitle-1 text-md-h6 font-weight-medium text-md-center white--text shadowed mt-1 px-3 mb-0 lh-14 mx-auto">
                                    {{ firstName }} has asked us to share the following information with their emergency contacts.
                                    <span class="d-block mt-3 mt-md-1 mb-1">Please read carefully as {{ firstName }} may need you to take action or relay this information to others.</span>
                                </p>
                                
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <v-alert dense class="elevation-1 mt-3 lh-12" type="info">
                    <b>For Demonstration Only</b><br>
                    The items below contain sensitive material and will only be accessible by your designated emergency contacts - requiring unique access codes sent to their phones each time they view.
                </v-alert>
                                
                <v-expansion-panels class="mt-3 no-print" id="alert-notes">
                    <v-expansion-panel>
                        <v-expansion-panel-header class="pl-3" @click="scrollToNote()">
                            <span class="font-weight-medium secondary--text title"><v-icon color="primary" class="mr-1 alert-note-icon">fa fa-map-marker-alt</v-icon> Last Login Location</span>
                            
                        </v-expansion-panel-header>
                        <v-expansion-panel-content eager>
                            <v-divider />
                            <v-alert outlined type="info">
                                <p class="body-2 secondary--text mb-2 mb-md-0">
                                    {{ firstName }} provided their location during a recent use of this app.
                                </p>
                                <p class="body-2 secondary--text mb-0">
                                    <b>Note: </b>This location was last updated <b>{{ $moment().subtract(1, 'day').fromNow() }}</b>. 
                                    Additionally, internet geolocation is not always accurate. 
                                </p>
                            </v-alert>
                            
                            <v-row>
                                <v-col cols="12">
                                    <v-card-text class="px-0 py-0" :key="userKey">
                                        <iframe
                                            width="100%"
                                            height="300"
                                            style="border:0"
                                            class="elevation-2"
                                            loading="lazy"
                                            allowfullscreen
                                            src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBmlVSyO63l-PzJI0EH3GjhavC0fD2wR5g&q=35.227085,-80.843124"
                                        >
                                        </iframe>
                                    </v-card-text>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <template v-for="item in alertUser.notes">
                        <v-expansion-panel :key="item.uuid" @click="preview=null">
                            <v-expansion-panel-header class="pl-3" @click="scrollToNote()">
                                <span class="font-weight-medium secondary--text title"><v-icon color="primary" class="mr-1 alert-note-icon">{{ getIcon(item.type) }}</v-icon> {{ getType(item.type) }}</span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content eager>
                                <v-divider />
                                <NoteDemo :note="item" :name="firstName" class="mt-1 mb-0" />
                                <v-card class="elevation-2 mb-0" v-if="item.files.length">
                                    <v-card-title class="subtitle-1 font-weight-medium grey lighten-3 secondary--text py-1"><v-icon color="primary" x-small class="mr-0 d-none">fa fa-paperclip</v-icon> This note has {{ item.files.length }} attachment{{ (item.files.length == 1) ? '' : 's' }}:</v-card-title>
                                    <v-divider />
                                    <v-card-text class="py-1">
                                        <v-list class="pt-0 pb-0">
                                            <template v-for="(file, idx) in item.files">
                                                <v-list-item :key="file.uuid" :class="(item.files.length==1) ? 'pl-0 delevation-2' : 'pl-0'" @click="preview=idx">
                                                    <v-list-item-avatar size="100" tile class="elevation-3">
                                                        <v-img v-if="file.type == 1" cover class="secondary" max-width="100" max-height="100" :src="file.file" />
                                                        <div v-if="file.type==2" style="width: 100%;" class="grey lighten-3 text-center pt-3 py-md-5">
                                                            <v-icon :size="($vuetify.breakpoint.smAndDown) ? 50 : 80" color="primary" class="mt-1">fa fa-file-pdf</v-icon>
                                                        </div>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title class="secondary--text body-2 text-center text-md-left">{{ file.caption || file.name }}</v-list-item-title>
                                                    </v-list-item-content>
                                                    <v-list-item-action class="hidden-sm-and-down">
                                                        <v-btn :href="file.file" small target="_blank" outlined color="primary">View</v-btn>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </template>
                                        </v-list>
                                    </v-card-text>
                                    <MediaViewer v-if="preview!==null" @closed="preview=null" :preview="preview" :files="item.files" />
                                </v-card>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </template>
                </v-expansion-panels>

                <v-row dense class="mt-1 mt-md-2 no-print">
                    <v-col cols="12">
                        <v-card class="elevation-2" color="white">
                            <v-card-text class="px-4 px-md-8 py-2 d-flex align-center justify-center">
                                <div style="width: 100%;">
                                    <h1 class="title secondary--text text-center mt-3 mb-2 mx-auto" style="max-width: 680px;">We hope {{ firstName }} is okay!</h1>
                                    <p class="subtitle-1 text-center secondary--text lh-14 mb-2 mb-md-0">The last check-in from <span class="text-capitalize">{{ firstName }}</span> was received on {{ $moment().subtract(1,'days').format('MMMM Do [at 2:00pm]') }} (EST).</p>
                                    <p class="subtitle-1 text-center secondary--text lh-14 mb-3">If you're one of their contacts, you will be notified if {{ firstName }} responds to our check-in.</p>
                                    <p class="body-2 text-center secondary--text"><v-icon small color="secondary" class="mr-1">fa fa-eye</v-icon> This alert has been viewed {{ alert.views || 1 }} time{{ (alert.views > 1) ? 's' : '' }}.</p>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                
            </v-col>
        </v-row>
    </div>
</template>
<script>

import { mapState } from 'vuex'

export default {
    name: 'AlertDemo',
    components: {
        NoteDemo: () => import('@/components/alert/NoteDemo'),
        MediaViewer: () => import('@/components/common/MediaViewer'),
    },
    computed: {
        ...mapState(['user']),
    },
    data: () => ({
        accessCode: '',
        alert: {},
        contact: {},
        errors: null,
        isAuthd: false,
        loading: true,
        preview: null,
        showAuth: true,
        showGeo: false,
        thisTimezone: '',
        alertUser: {
            notes: [
                { uuid: '234', type: 1, body: '{}', files:[] },
                { uuid: '334', type: 2, body: '{}', files:[] },
                { uuid: '434', type: 3, body: '{}', files:[] },
                { uuid: '534', type: 4, body: '{}', files:[] },
                { uuid: '634', type: 5, body: '{}', files:[] }
            ]
        },
        userKey: 0,
        firstName: 'Jane'
    }),
    methods: {
        initData(){
        },
        scrollToNote(){
            setTimeout(() => {
                if(document.querySelector('.v-expansion-panel-header--active')){
                    this.$vuetify.goTo('.v-expansion-panel-header--active', {
                        container: 'body',
                        offset: 10
                    });
                }else{
                    this.$vuetify.goTo('.v-expansion-panels', {
                        container: 'body',
                        offset: 20
                    });
                }
            }, 100);
        },
        getIcon(itemType){
            if(itemType == 1){
                return 'fa fa-notes-medical';
            }else if(itemType == 3){
                return 'fa fa-dog';
            }else if(itemType == 2){
                return 'fa fa-users';
            }else if(itemType == 4){
                return 'fa fa-home';
            }

            return 'fa fa-comment-medical';
        },
        getType(itemType){
            if(itemType == 1){
                return 'Medical Notes';
            }else if(itemType == 3){
                return 'Pet Care Notes';
            }else if(itemType == 2){
                return 'Family Care Notes';
            }else if(itemType == 4){
                return 'Property Notes';
            }else if(itemType == 5){
                return 'Other Emergency Notes';
            }
        },
        getNoteDesc(idx){
            if(idx == 1){
                return 'May include medication allergies or medical conditions ' + this.firstName + ' may have.';
            }else if(idx == 2){
                return 'Actions that may be needed for those left in their care.';
            }else if(idx == 3){
                return 'Instructions for feeding and caring for their pets.';
            }else if(idx == 4){
                return 'Notes for accessing and caring for their home and plants.';
            }

            return 'Notes that ' + this.firstName + ' asked us to share with you.';
        },
    },
    mounted(){
		this.initData();
	},
    watch: {

    }
}
</script>